<!-- 课堂互动 -->
<style lang="scss" scoped>
@import '@/assets/styles/questions.scss';

.page {
  padding: 20px 0;
  height: 100%;
  overflow: auto;
  .course_brief {
    background: #ffffff;
    border-radius: 14px;
    padding: 32px;
    .course {
      line-height: 30px;
      display: flex;
      .el-tag {
        float: left;
        margin-right: 20px;
      }
    }
    h4 {
      font-size: 18px;
      font-weight: bold;
    }
    .userInfo {
      margin-top: 20px;
      .img {
        width: 72px;
        height: 72px;
        border-radius: 50%;
        margin-right: 16px;
        object-fit: cover;
      }
      .teacherName {
        font-size: 18px;
        font-weight: 400;
        color: #444444;
        line-height: 28px;
      }
      .teacherTitle {
        margin-top: 6px;
        height: 28px;
        font-size: 16px;
        font-weight: 400;
        color: #888888;
        line-height: 28px;
      }
    }
  }
  .questions .li:hover{
    cursor: pointer;
    box-shadow: 0px 14px 27px -17px #B7D0DE;
  }
}
</style>

<template>
  <div class="page">
    <div class="course_brief">
      <div class="course" v-if="practice_info.sysub_name">
        <el-tag>{{ practice_info.sysub_name || "-" }}</el-tag>
        <h4 class="flex_1">{{ practice_info.sccou_cha_title || "-" }}</h4>
      </div>
      <div class="userInfo flex_align">
        <img class="img" :src="practice_info.teuse_image?practice_info.teuse_image:require('@assets/images/empty_avatar.png')" alt="" />
        <div>
          <div class="teacherName">{{ practice_info.teuse_name }}</div>
          <div class="teacherTitle">授课老师</div>
        </div>
      </div>
    </div>
    <div class="questions">
      <div v-for="(item, index) in practice_info.practiceQuestionData" :key="index" >
        <div class="type flex_align" v-if="index == 0">
          {{ item.syque_typ_name }}
        </div>
        <div class="type flex_align" v-else-if="practice_info.practiceQuestionData[index - 1].syque_typ_name !=item.syque_typ_name" >
          {{ item.syque_typ_name }}
        </div>
        <div class="li" @click="goDetails(index)">
          <div class="topic">
            <div class="flex">
              <div class="number">{{ index + 1 }}、</div>
              <!-- <el-image v-if="item.teles_pra_title_image" style="width: 100px; height: 100px" :src="item.teles_pra_title_image" :preview-src-list="[item.teles_pra_title_image]"></el-image> -->
              <div class="val richflex" v-html="item.teles_pra_title"></div>
            </div>
            <img class="selectimg" v-if="item.teles_pra_title_image" :src="item.teles_pra_title_image" alt="">
            <!-- <el-image
              v-if="item.teles_pra_title_img"
              style="width: 100px; height: 100px"
              :src="item.teles_pra_title_img"
              :preview-src-list="[item.teles_pra_title_img]"
            >
            </el-image> -->
          </div>
          <!-- 单选题  syque_typ_id   1单项选择 2多项选择 3单项填空 4多项填空 5判断 6计算 7简答 8问答 14组合提-->
          <div
            class="questionType"
            v-if="item.syque_typ_id == 1 || item.syque_typ_id == 5"
          >
            <div class="answer">
              <div class="title">正确答案:</div>
              <div class="flex_1">
                <span class="val" v-for="(item2,index2) in  item.sure_answer" :key="index2"> <span v-if="index2 > 0">,</span>{{item2}}</span>
              </div>
            </div>
            <div class="answer mistake" :class="item.tepralog_status == 2 ? 'mistake' : ''" >
              <div class="title">你的答案:</div>
              <div class="flex_1 flex">
                <div class="val">
                  <span
                    v-for="(item2, index2) in item.tepralog_stu_answer"
                    :key="index2"
                  >
                    <span v-if="index2 > 0">,</span>
                    {{ item2 }}
                  </span>
                </div>
                <div class="iconfont dui" v-if="item.tepralog_status == 1" >&#xe6db;</div>
                <div class="iconfont cuo" v-if="item.tepralog_status == 2" >&#xe6dc;</div>
              </div>
            </div>
          </div>
          <!-- 多选题 -->
          <div class="questionType" v-if="item.syque_typ_id == 2">
            <div class="answer">
              <div class="title">正确答案:</div>
              <div class="flex_1">
                <span class="val" v-for="(item2,index2) in  item.sure_answer" :key="index2"> <span v-if="index2 > 0">,</span>{{item2}}</span>
              </div>
            </div>
            <!-- class=answer mistake 错 -->
            <div
              class="answer"
              :class="item.tepralog_status == 2 ? 'mistake' : ''"
            >
              <div class="title">你的答案:</div>
              <div class="flex_1 flex">
                <div class="val">
                  <span
                    v-for="(item2, index2) in item.tepralog_stu_answer"
                    :key="index2"
                  >
                    <span v-if="index2 > 0">,</span>
                    {{ item2 }}
                  </span>
                </div>
                <div class="iconfont dui" v-if="item.tepralog_status == 1" >&#xe6db;</div>
                <div class="iconfont cuo" v-if="item.tepralog_status == 2" >&#xe6dc;</div>
              </div>
            </div>
          </div>
          <!-- 填空题 -->
          <div
            class="questionType"
            v-if="item.syque_typ_id == 3 || item.syque_typ_id == 4|| item.syque_typ_id == 7"
          >
            <img class="selectimg" v-if="item.tepralog_stu_answer_img" :src="item.tepralog_stu_answer_img" alt="">
            <!-- <el-image v-if="item.tepralog_stu_answer_img" style="width: 100px; height: 100px" :src="item.tepralog_stu_answer_img" :preview-src-list="[item.tepralog_stu_answer_img]"  > </el-image> -->
            <div class="answer" :class="item.tepralog_status==2?'mistake':''" v-if="practice_info.isRead==1">
              <div class="title">你的答案:</div>
              <div class="flex_1">
                <div class="val" v-if="item.sthom_que_stuanswer">{{item.sthom_que_stuanswer}}</div>
                <div class="iconfont dui" v-if="item.tepralog_status == 1" >&#xe6db;</div>
                <div class="iconfont cuo" v-if="item.tepralog_status == 2" >&#xe6dc;</div>
              </div>
            </div>

          </div>
          <!-- 阅读题 -->
          <!-- <div class="questionType" v-if="item.syque_typ_id == 7">
            <div class="answer">
              <div class="title">正确答案:</div>
              <div class="flex_1">
                <div class="val">{{ item.tepralog_succ_answer }}</div>
              </div>
            </div>
            <div
              class="answer"
              :class="item.tepralog_status == 2 ? 'mistake' : ''"
            >
              <div class="title">你的答案:</div>
              <div class="flex_1">
                <div class="val" v-if="item.sthom_que_stuanswer">
                  {{ item.sthom_que_stuanswer }}
                </div>
                <div class="iconfont dui" v-if="item.tepralog_status == 1" >&#xe6db;</div>
                <div class="iconfont cuo" v-if="item.tepralog_status == 2" >&#xe6dc;</div>
              </div>
            </div>
          </div> -->
          <!-- 组合提 -->
          <div class="questionType" v-if="item.syque_typ_id == 14">
            <div class="subtopic-item" v-for="(item2, index2) in item.question_item" :key="index2" >
              <div class="type flex_align" v-if="index2 == 0">
                {{ item2.syque_typ_name }}
              </div>
              <div class="type flex_align" v-else-if="item.question_item[index2 - 1].syque_typ_name !=item2.syque_typ_name" >
                {{ item2.syque_typ_name }}
              </div>
              <div class="topic">
                <div class="flex">
                  <div class="number">({{ index2 + 1 }})、</div>
                  <!-- <el-image v-if="item.teles_pra_title_image" style="width: 100px; height: 100px" :src="item.teles_pra_title_image" :preview-src-list="[item.teles_pra_title_image]"></el-image> -->
                  <div class="val richflex" v-html="item2.teles_pra_title"></div>
                </div>
                <img class="selectimg" v-if="item2.teles_pra_title_image" :src="item2.teles_pra_title_image" alt="">
							  <!-- <el-image
                  v-if="item2.teles_pra_title_img"
                  style="width: 100px; height: 100px"
                  :src="item2.teles_pra_title_img"
                  :preview-src-list="[item2.teles_pra_title_img]"
                >
                </el-image> -->
              </div>
              
              <!-- 单选题  syque_typ_id   1单项选择 2多项选择 3单项填空 4多项填空 5判断 6计算 7简答 8问答-->
              <div
                class="questionType"
                v-if="item2.syque_typ_id == 1 || item2.syque_typ_id == 5"
              >
                <div class="answer">
                  <div class="title">正确答案:</div>
                  <div class="flex_1">
                    <span class="val" v-for="(item3,index3) in  item2.sure_answer" :key="index3"> <span v-if="index3 > 0">,</span>{{item3}}</span>
                    <!-- <div class="val">{{ item2.tepralog_succ_answer }}</div> -->
                  </div>
                </div>
                <div class="answer mistake" :class="item2.tepralog_status == 2 ? 'mistake' : ''" >
                  <div class="title">你的答案:</div>
                  <div class="flex_1 flex">
                    <div class="val">
                      <span
                        v-for="(item3, index3) in item2.tepralog_stu_answer" :key="index3">
                        <span v-if="index3 > 0">,</span>
                        {{ item3 }}
                      </span>
                    </div>
                    <el-image v-if="item2.tepralog_stu_answer_image" style="width: 60px; height: 90px" :src="item2.tepralog_stu_answer_image" :preview-src-list="[item2.tepralog_stu_answer_image]"  > </el-image>
                    <div class="iconfont dui" v-if="item2.tepralog_status == 1" >&#xe6db;</div>
                    <div class="iconfont cuo" v-if="item2.tepralog_status == 2" >&#xe6dc;</div>
                  </div>
                </div>
              </div>
              <!-- 多选题 -->
              <div class="questionType" v-if="item2.syque_typ_id == 2">
                <div class="answer">
                  <div class="title">正确答案:</div>
                  <div class="flex_1">
                    <span class="val" v-for="(item3,index3) in  item2.sure_answer" :key="index3"> <span v-if="index3 > 0">,</span>{{item3}}</span>
                    <!-- <div class="val">{{ item2.tepralog_succ_answer }}</div> -->
                  </div>
                </div>
                <!-- class=answer mistake 错 -->
                <div
                  class="answer"
                  :class="item2.tepralog_status == 2 ? 'mistake' : ''"
                >
                  <div class="title">你的答案:</div>
                  <div class="flex_1 flex">
                    <div class="val">
                      <span v-for="(item3, index3) in item2.tepralog_stu_answer" :key="index3">
                        <span v-if="index3>0">,</span>{{ item3 }}
                      </span>
                    </div>
                    <el-image v-if="item2.tepralog_stu_answer_image" style="width: 60px; height: 90px" :src="item2.tepralog_stu_answer_image" :preview-src-list="[item2.tepralog_stu_answer_image]"  > </el-image>
                    <div class="iconfont dui" v-if="item2.tepralog_status == 1" >&#xe6db;</div>
                    <div class="iconfont cuo" v-if="item2.tepralog_status == 2" >&#xe6dc;</div>
                  </div>
                </div>
              </div>
              <!-- 填空题 -->
              <div class="questionType" v-if="item2.syque_typ_id == 3 || item2.syque_typ_id == 4 || item2.syque_typ_id == 7">
                <img class="selectimg" v-if="item2.tepralog_stu_answer_img" :src="item2.tepralog_stu_answer_img" alt="">
                <!-- <el-image v-if="item2.tepralog_stu_answer_img" style="width: 100px; height: 100px" :src="item2.tepralog_stu_answer_img" :preview-src-list="[item2.tepralog_stu_answer_img]"  > </el-image> -->
                <div class="answer" :class="item2.tepralog_status==20?'mistake':''">
                  <div class="title">你的答案:</div>
                  <div class="flex_1">
                    <div class="val" v-if="item2.sthom_que_stuanswer">{{item2.sthom_que_stuanswer}}</div>
                    <el-image v-if="item2.tepralog_stu_answer_image" style="width: 60px; height: 90px" :src="item2.tepralog_stu_answer_image" :preview-src-list="[item2.tepralog_stu_answer_image]"  > </el-image>
                    <div class="iconfont dui" v-if="item2.tepralog_status == 10" >&#xe6db;</div>
                    <div class="iconfont cuo" v-if="item2.tepralog_status == 20" >&#xe6dc;</div>
                  </div>
                </div>
              </div>
              <!-- 阅读题 -->
              <!-- <div class="questionType" v-if="item2.syque_typ_id == 7">
                <div class="answer">
                  <div class="title">正确答案:</div>
                  <div class="flex_1">
                    <div class="val">{{ item2.tepralog_succ_answer }}</div>
                  </div>
                </div>
                <div
                  class="answer"
                  :class="item2.tepralog_status == 2 ? 'mistake' : ''"
                >
                  <div class="title">你的答案:</div>
                  <div class="flex_1">
                    <div class="val" v-if="item2.sthom_que_stuanswer">
                      {{ item2.sthom_que_stuanswer }}
                    </div>
                    <div class="iconfont dui" v-if="item2.tepralog_status == 1" >&#xe6db;</div>
                    <div class="iconfont cuo" v-if="item2.tepralog_status == 2" >&#xe6dc;</div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { practice_info } from "@api/home";
export default {
  name: "keyPoint",
  data() {
    return {
      // 授课课堂id 32
      tecla_id: 0,
      //课堂作业详情
      practice_info: {},
    };
  },
  created() {
    this.tecla_id = this.$route.params.id;
    this.getData();
  },
  methods: {
    /** 获取公开课列表数据 */
    async getData() {
      let { data } = await practice_info({ tecla_id: this.tecla_id });
      this.practice_info = data;
      this.$forceUpdate();
    },
    
    // 跳转详情
    goDetails(index){
        this.$router.push({
            name:"QUESTIONS_DETAILS",
            params: {
            index: index,
            id:this.tecla_id
            }
        })
    }
  },
};
</script>